import Image from 'next/image'
import { DragPreviewImage, useDrag } from 'react-dnd'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'

export default function Bone3Highlight ({ isSmallScreen, useLine, openModalMobile }: {isSmallScreen?: boolean, useLine?: boolean, openModalMobile?: any}) {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'bone3Highlight',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isSafari, setIsSafari] = useState(false)

  const handleMove = (event: any) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    setPosition({ x: clientX - window.innerWidth + 80, y: 0 });
  };

  useEffect(() => {
    setTimeout(() => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      setIsSafari(isSafari)
    }, 100)
    const container: any = document.getElementById('image-preview-container3');
    if(container) {
      container.addEventListener('touchmove', handleMove);
    }

    return () => {
      if(container) {
        container.removeEventListener('touchmove', handleMove);
      }
    };
  }, []);

  useEffect(() => {
    if(position.x > -window.innerWidth + 160 && position.x <= -window.innerWidth + 170 && position.y > -25 && position.y < 25)
      openModalMobile(2)
  }, [position.x])

  useEffect(() => {
    if(isDragging) {
      document.getElementById('bone-3-highlight')?.classList.remove(styles.float)
    } else document.getElementById('bone-3-highlight')?.classList.add(styles.float)
  }, [isDragging])

  return (
    <div className="flex justify-center">
      {
        !isSmallScreen && <DragPreviewImage connect={preview} src={isSafari ? '/bone3-preview-safari.webp' : 'bone3-preview.webp'} />
      }
      {
        isDragging && useLine &&
        <div className='absolute top-[65%] w-[558px] -left-[460%]'>
          <svg width="560" height="499" viewBox="0 0 560 499" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.animation}>
            <path d="M560 1H458C444.745 1 434 11.7452 434 25V474C434 487.255 423.255 498 410 498H151.5C138.245 498 127.5 487.255 127.5 474V408C127.5 394.745 116.755 384 103.5 384H0" stroke="#B0B0B0"/>
          </svg>
        </div>
      }
      <div className='h-[203px]'>
        <div id="image-preview-container3">
          {
              isSmallScreen && 
              <>
                <Image 
                  src='/bone3-preview.webp' 
                  width={100} 
                  height={100} 
                  alt='preview' 
                  style={{
                    transform: `translate(${position.x}px, ${position.y}px)`,
                    position: 'absolute',
                    display: isDragging  ? 'block' : 'none'
                  }}
                />
                <Image
                  ref={drag}
                  src='/bone3-highlighted-with-shadow.webp'
                  alt="bone"
                  title="bone"
                  width={84}
                  height={170}
                  className='h-[170px] mr-3 w-auto'
                />
              </>
            }
        </div>
        {
          !isDragging && !isSmallScreen &&
          <Image
            ref={drag}
            src='/bone3-highlighted-with-shadow.webp'
            id='bone-3-highlight'
            alt="bone"
            title="bone"
            width={100}
            height={203}
            className={`${styles.float} w-auto h-[203px] mr-3`}
          />
        }
      </div>
    </div>
  )
}
