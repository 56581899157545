import Image from 'next/image'
import { DragPreviewImage, useDrag } from 'react-dnd'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'

export default function Bone1Highlight ({ isSmallScreen, useLine , openModalMobile}: {isSmallScreen?: boolean, useLine?: boolean, openModalMobile?: any}) {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'bone1Highlight',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isSafari, setIsSafari] = useState(false)

  const handleMove = (event: any) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    setPosition({ x: clientX - 140, y: -10 });
  };

  useEffect(() => {
    setTimeout(() => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      setIsSafari(isSafari)
    }, 100)
    const container: any = document.getElementById('image-preview-container');
    if(container) {
      container.addEventListener('touchmove', handleMove);
    }

    return () => {
      if(container) {
        container.removeEventListener('touchmove', handleMove);
      }
    };
  }, []);

  useEffect(() => {
    if(position.x <= window.innerWidth - 190 && position.x > window.innerWidth - 198 && position.y > -25 && position.y < 25)
      openModalMobile(0)
  }, [position.x])

  useEffect(() => {
    if(isDragging) {
      document.getElementById('bone-1-highlight')?.classList.remove(styles.float)
    } else document.getElementById('bone-1-highlight')?.classList.add(styles.float)
  }, [isDragging])

  return (
    <>
      {
        !isSmallScreen && <DragPreviewImage connect={preview} src={isSafari ? '/bone1-preview-safari.webp' : 'bone1-preview.webp'} />
      }
      {
        isDragging && useLine &&
        <div className={styles['align-progress-line']}>
          <svg width="558" height="385" viewBox="0 0 558 385" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.animation}>
            <path d="M0.000976562 1H86.0002C99.255 1 110 11.7452 110 25V60C110 73.2548 120.745 84 134 84H442C455.255 84 466 94.7452 466 108V360.5C466 373.755 476.745 384.5 490 384.5H560" stroke="#B0B0B0"/>
          </svg>
        </div>
      }
      <div className="flex justify-center">
        <div className='h-[220px]'>
          <div id="image-preview-container">
            {
              isSmallScreen && 
              <>
                <Image 
                  src='/bone1-preview.webp' 
                  width={100} 
                  height={100} 
                  alt='preview' 
                  style={{
                    transform: `translate(${position.x}px, ${position.y}px)`,
                    position: 'absolute',
                    display: isDragging  ? 'block' : 'none'
                  }}
                />
                <Image
                  ref={drag}
                  src='/bone1-highlighted-with-shadow.webp'
                  alt="bone"
                  title="bone"
                  width={66}
                  height={180}
                  className='h-[180px] mr-3 w-auto'
                />
              </>
            }
            {
              !isDragging && !isSmallScreen &&
              <Image
                ref={drag}
                src='/bone1-highlighted-with-shadow.webp'
                alt="bone"
                title="bone"
                id='bone-1-highlight'
                width={82}
                height={220}
                className={`${styles.float} h-[220px] mr-4 w-auto`}
              />
            }
          </div>
        </div>
      </div>
    </>
  )
}
