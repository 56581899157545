import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import useTranslation from 'next-translate/useTranslation'

export default function Navbar ({ activeItem }: any) {
  const [activeMenuItem, setActiveMenuItem] = useState('intro')
  const [oldActiveMenuItem, setOldActiveMenuItem] = useState('intro')
  const { t, lang } = useTranslation('common')

  const menuItems = [
    t('menu.intro'),
    t('menu.discover'),
    t('menu.benefits'),
    t('menu.guards'),
    t('menu.roadmap'),
    t('menu.docs'),
    t('menu.friends'),
    t('menu.contact'),
    t('menu.newsletter')
  ]

  useEffect(() => {
    handleChooseItem(activeItem)
  }, [activeItem])

  const handleChooseItem = (item: string) => {
    const itemToChange = activeMenuItem
    setOldActiveMenuItem(activeMenuItem)
    setActiveMenuItem(item)
    setTimeout(() => {
      const index = menuItems.indexOf(itemToChange)
      document.getElementById(`old-menu-item-${index}`)?.classList.add('hidden')
    }, 500)
  }
  return (
    <>
      {
        menuItems.map((menuItem, index) => {
          return (
            <div key={index}>
              <div onClick={() => handleChooseItem(menuItem)}>
                <a href={`#${menuItem}`} className='flex items-center mb-2 w-full'>
                  <div className={activeMenuItem === menuItem ? styles['active-box'] : styles.box}></div>
                  <div className={activeMenuItem === menuItem ? styles['active-menu-item'] : styles['menu-item']}>{menuItem}</div>
                </a>
              </div>
              <div
                className={`
                  my-2 w-[1px] bg-[#D9D9D9] ml-1 h-10
                  ${styles['fill-animation']} 
                  ${activeMenuItem === menuItem && activeMenuItem !== 'newsletter' ? 'block relative' : 'hidden absolute'}`
                }></div>
              <div
                id={`old-menu-item-${index}`}
                className={`
                  my-2 w-[1px] bg-[#D9D9D9] ml-1 h-10
                  ${styles['empty-animation']} 
                  ${oldActiveMenuItem === menuItem && oldActiveMenuItem !== activeMenuItem ? 'block' : 'hidden'}`
                }></div>
            </div>
          )
        })
      }
    </>
  )
}
