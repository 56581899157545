import styles from "./styles.module.css";
import useTranslation from "next-translate/useTranslation";
import { useInView } from "react-intersection-observer";

export default function WhitePaper({ firstAccess }: { firstAccess: boolean }) {
  const { t, lang } = useTranslation("common");
  const { ref, inView } = useInView({ threshold: 0.5 });

  return (
    <section ref={ref} className={`section ${!inView && "opacity-0"}`}>
      <div
        className={`${!inView && !firstAccess && "opacity-0"} ${
          inView && !firstAccess && "opacity-animation-long"
        }`}
        style={{
          transition: !firstAccess
            ? "all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s"
            : "none",
          animationDuration: !firstAccess ? "1s" : "0s",
        }}
      >
        <h1 className="xs:font-semibold md:font-bold my-10 font-[ThunderBold]">
          {t("docs.whitepaper")}
        </h1>
        <p className="max-w-[450px] text-left text-white">
          {t("docs.description")}
        </p>
        <a
          href={lang === "en" ? "/whitepaper.pdf" : "/whitepaper-ro.pdf"}
          target="_blank"
          className={`
            mt-[72px] pr-8 border-solid border-[1px] rounded-tl-[8px] rounded-br-[8px] font-light
            border-primary text-primary flex items-center ${styles["hover-effect"]} w-fit
          `}
        >
          <div className="py-2 pl-8 border-[1px] border-transparent rounded-tl-[8px] rounded-br-[8px] h-full">
            {t("docs.read")}&nbsp;
          </div>
          <div className="py-2">{t("docs.more")}</div>
          <div className="ml-4">
            <svg
              width="6"
              height="10"
              viewBox="0 0 6 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 9L5 5L1 1" stroke="#FFD10A" strokeLinecap="round" />
            </svg>
          </div>
        </a>
      </div>
    </section>
  );
}
