import styles from './styles.module.css'

export default function ArrowDown () {
  return (
    <div className={styles['arrows-container']}>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"/>
      </svg>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"/>
      </svg>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="white" strokeLinecap="round"/>
      </svg>
    </div>
  )
}
