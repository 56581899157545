import { useEffect, useState } from 'react'
import styles from './styles.module.css'

export default function Modal ({ open, title, description, content, setOpenModal }: {open: number, title: string, description: string, content: any, setOpenModal: any}) {
  const [, setDisplayModal] = useState(false)

  useEffect(() => {
    setDisplayModal(true)
    document.body.style.overflow = 'hidden';
  }, [open])

  const handleCloseModal = () => {
    document.body.style.overflow = 'auto';
    setDisplayModal(false)
    setOpenModal(-1)
    document.getElementById('modal')?.classList.add(styles['close-animation'])
    setTimeout(() => {
      document.getElementById('modal')?.classList.remove('block')
      document.getElementById('modal')?.classList.remove('fixed')
      document.getElementById('modal')?.classList.add('hidden')
    }, 100)
  }

  return (
    <div className='fixed w-screen h-screen top-0 left-0 z-40 flex justify-center items-center'>
      <div
        id='modal'
        className='
          relative max-w-full w-[844px] h-full md:h-[726px] bg-white pb-[72px] px-[48px] 
          max-h-screen overflow-y-scroll overflow-x-hidden rounded-none md:rounded-tr-[24px] md:rounded-br-[24px]'
      >
        <div 
          className='sticky w-full top-0 flex flex-col items-start pt-[72px] pb-[28px]' 
          style={{background: 'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%)'
        }}>
          <div className='w-full flex justify-end'>
            <button onClick={() => handleCloseModal()} name='close modal'>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M30.1422 30.1422L16 16M16 16L1.85791 1.85791M16 16L1.85791 30.1422M16 16L30.1422 1.85791" stroke="#111111" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </button>
          </div>
          <span className='text-[#111] font-[ThunderBold] text-[96px] xs:font-semibold md:font-bold'>
            {title}
          </span>
        </div>
        <div className='mb-[32px]'>
          <span className='text-[#111] font-[BeVietnam] text-[24px] font-semibold leading-[40px]'>
            {description}
          </span>
        </div>
        <div
          className={styles['column-fit']}
        >
          {
            content.map((paragraph: string, index: number) => {
              return (
                <p key={index} className='w-full text-[#111] font-[BeVietnam] text-[16px]'>
                  {paragraph} <br /> <br />
                </p>
              )
            })
          }
        </div>
      </div>
      <div className={styles.shadow}></div>
    </div>
  )
}
