
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Newsletter from '@components/atoms/Newsletter';
import { DefaultLayout } from '@components/layouts/default.layout';
import Navbar from '@components/molecules/Navbar';
import NavbarMobile from '@components/molecules/NavbarMobile';
import SocialMenu from '@components/molecules/SocialMenu';
import Discover from '@components/organism/Discover';
import Docs from '@components/organism/Docs';
import Header from '@components/organism/Header';
import HeaderMobile from '@components/organism/HeaderMobile';
import OurBenefits from '@components/organism/OurBenefits';
import { useIsMobile } from '@hooks/useIsMobile';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';
const NestGuards = dynamic(() => import('@components/organism/NestGuards'), {
    ssr: true
});
const Roadmap = dynamic(() => import('@components/organism/Roadmap'), {
    ssr: true
});
const Friends = dynamic(() => import('@components/organism/Friends'), {
    ssr: true
});
const Footer = dynamic(() => import('@components/organism/Footer'), {
    ssr: true
});
export default function Home() {
    const isMobile = useIsMobile();
    const [activeItem, setActiveItem] = useState('intro');
    const router = useRouter();
    const { t, lang } = useTranslation('common');
    useEffect(() => {
        setTimeout(() => {
            const anchor = document.getElementById(router.asPath.slice(2, router.asPath.length));
            if (anchor) {
                anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 1000);
    }, [router]);
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 100);
    }, []);
    return (<>
      <Head>
        <title>{t('title')}</title>
        <meta name='description' content='The first real-world business club based on access through NFT.'/>
        <link rel='icon' href='/favicon.png'/>
      </Head>
      <div className='bg-[#111]'>
        <div className='fixed flex justify-center h-screen w-[50px] md:w-screen fixed-container-margin lg:z-30'>
          <div className='w-[1500px] max-w-full flex justify-end md:justify-between'>
            {isMobile ? (<NavbarMobile activeItem={activeItem}/>) : (<div className='mt-[33%] w-28 ml-4'>
                <Navbar activeItem={activeItem}/>
              </div>)}
            <div className='mt-[33vh] md:mt-[33%] mr-4'>
              <SocialMenu />
            </div>
          </div>
        </div>
        {isMobile ? (<HeaderMobile setIntroMenuItem={setActiveItem}/>) : (<Header setIntroMenuItem={setActiveItem}/>)}
        <div className='flex flex-col items-center'>
          <div className='section relative lg:z-40'>
            <Discover setDiscoverMenuItem={setActiveItem}/>

            <OurBenefits setDiscoverMenuItem={setActiveItem}/>

            <NestGuards setDiscoverMenuItem={setActiveItem}/>

            <Roadmap setDiscoverMenuItem={setActiveItem}/>

            <Docs setDiscoverMenuItem={setActiveItem}/>

            <Friends setDiscoverMenuItem={setActiveItem}/>

            <Footer setDiscoverMenuItem={setActiveItem}/>

            <Newsletter setDiscoverMenuItem={setActiveItem}/>

            <div className='section flex justify-center'>
              <div className='flex justify-between flex-col md:flex-row max-w-full w-[1000px] gap-0 lg:gap-10 pb-[120px] md:pb-[160px] px-4'>
                <div>
                  <Image loading='lazy' src='/bidhouse-white.svg' width={178} height={40} alt='bidhouse logo'/>
                  <p className='w-[270px] text-left text-white mt-6 md:mt-[46px]'>
                    {t('footer.access')}
                  </p>
                </div>
                <div className='flex flex-col justify-between'>
                  <div className='flex mt-8 flex-col md:flex-row'>
                    <Link href='/terms-and-conditions' className='text-white text-[16px] font-light font-[BeVietnam] mr-4 lg:mr-8 mb-6 md:mb-0'>
                      {t('footer.terms')}
                    </Link>
                    <Link href='/privacy-policy' className='text-white text-[16px] font-light font-[BeVietnam] mr-4 lg:mr-8 mb-6 md:mb-0'>
                      {t('footer.privacy')}
                    </Link>
                    <Link href='/cookie-policy' className='text-white text-[16px] font-light font-[BeVietnam] mr-4 lg:mr-8 mb-6 md:mb-0'>
                      {t('footer.cookie')}
                    </Link>
                    <Link href='/disclaimer' className='text-white text-[16px] font-light font-[BeVietnam]'>
                      {t('footer.disclaimer')}
                    </Link>
                  </div>
                  <p className='text-white mt-20 md:mt-0 text-center md:text-start'>
                    ©{t('footer.rights')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
}
Home.getLayout = function getLayout(page: ReactElement) {
    return <DefaultLayout>{page}</DefaultLayout>;
};

    async function __Next_Translate__getStaticProps__18fdd60b2fb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18fdd60b2fb__ as getStaticProps }
  