import Image from 'next/image'
import { useDrop } from 'react-dnd'

export default function Bone2 ({ setOpenModal, isMobile }: any) {
  const moveBone2Highlight = () => {
    setOpenModal(1)
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'bone2Highlight',
    drop: () => moveBone2Highlight(),
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  }), [])

  return (
    <div className='flex justify-center'>
      <Image ref={drop} src='/bone2.svg' alt='bone' title='bone'  width={isMobile? 100 : 125} height={isMobile ? 204 : 252} />
    </div>
  )
}
