import Loader from '@components/atoms/Loader'
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head'
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactNode, useEffect, useState } from 'react'

type Props = {
    children?: ReactNode;
};

export function DefaultLayout ({ children }: Props) {
  const { t, lang } = useTranslation()
  const router = useRouter();
  const [shouldHavePadding, setShouldHavePadding] = useState(true)

  useEffect(() => {
    if(router.pathname.includes('terms') || router.pathname.includes('policy')|| router.pathname.includes('disclaimer'))
      setShouldHavePadding(false)
  }, [])

  return (
    <div className="flex flex-col">
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="author" content="bidhouse" />
        <link rel="shortcut icon" href="/favicon.png" />
        <link
          rel="image_src"
          href='https://bidhouse.club/meta-image.webp'
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-W20FC50FR4"></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-W20FC50FR4');
            `,
          }}
        />
      </Head>
      <div className="flex flex-col bg-[#000000]">
        <div 
          className={`
            fixed w-full top-0 left-0 z-40 h-[100px] flex justify-between 
            items-center ${shouldHavePadding ? 'xs:pr-16' : 'xs:pr-4'} lg:pr-8
          `}
          style={{ background: 'linear-gradient(0deg, rgba(17, 17, 17, 0) 0.05%, rgba(17, 17, 17, 1) 40.59%)'}}
        >
          <Link href='/'>
            <Image width={20} height={31} src='/logo.svg' alt='logo' className='m-6' />
          </Link>
          <div className='flex items-center'>
            <Link href={router.pathname} locale="en" className={`language-button ${lang === 'en' && '!text-primary'}`}>
              EN
            </Link>
            <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.25" y1="1.09278e-08" x2="0.249999" y2="16" stroke="white" strokeWidth="0.5"/>
            </svg>
            <Link href={router.pathname} locale="ro" className={`language-button ${lang === 'ro' && '!text-primary'}`}>
              RO
            </Link>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
