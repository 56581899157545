import Image from "next/image";
import styles from './styles.module.css';
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function DocsAnimation ({setShouldDisplayWhitepaper}: {setShouldDisplayWhitepaper: any}) {
  const { ref, inView } = useInView({threshold: 0.5})
  const [startAnimation, setStartAnimation] = useState(false)

  useEffect(() => {
    if(inView && !startAnimation) {
      setStartAnimation(true)
    }
  }, [inView])

  useEffect(() => {
    if(startAnimation) {
      setShouldDisplayWhitepaper(true)
      setTimeout(() => {
        setStartAnimation(false)
        document.getElementById('logo-animation-container')?.classList.add('hidden')
      }, 2000)
    }
  }, [startAnimation])

  return (
    <div ref={ref} className='flex items-center justify-center' id='logo-animation-container'>
      <div className={startAnimation ? styles['left-animation'] : ''}>
        <Image loading='lazy' src='/docs-animation/left.svg' alt='logo left piece' width={75} height={156} />
      </div>
      <div className='flex flex-col mx-5'>
        <div className={`-ml-[35px] mb-[10px] ${startAnimation ? styles['top-animation'] : ''}`}>
          <Image loading='lazy' src='/docs-animation/right.svg' alt='logo left piece' width={75} height={156} />
        </div>
        <div className={`-mr-[35px] mt-[10px] ${startAnimation ? styles['bottom-animation'] : ''}`} >
          <Image loading='lazy' src='/docs-animation/left.svg' alt='logo left piece' width={75} height={156} />
        </div>
      </div>
      <div className={`${startAnimation ? styles['right-animation'] : ''}`}>
        <Image loading='lazy' src='/docs-animation/right.svg' alt='logo left piece' width={75} height={156} />
      </div>
    </div>
  )
}
