import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

export default function NavbarMobile ({ activeItem }: any) {
  const [activeMenuItem, setActiveMenuItem] = useState('intro')
  const [expandMenu, setExpandMenu] = useState(false)
  const { t, lang } = useTranslation('common')

  const menuItems = [
    t('menu.intro'),
    t('menu.discover'),
    t('menu.benefits'),
    t('menu.guards'),
    t('menu.roadmap'),
    t('menu.docs'),
    t('menu.friends'),
    t('menu.contact'),
    t('menu.newsletter')
  ]

  useEffect(() => {
    // handleChooseItem(activeItem)
    setActiveMenuItem(activeItem)
    setExpandMenu(false)
  }, [activeItem])

  const handleChooseItem = (item: string) => {
    setActiveMenuItem(item)
    setExpandMenu(false)
    document.getElementById('display-mobile-navbar')?.classList.remove(styles['button-animation'])
    document.getElementById('display-mobile-navbar')?.classList.add(styles['reverse-button-animation'])
  }

  const handleDisplayMenu = () => {
    if (expandMenu) {
      document.getElementById('display-mobile-navbar')?.classList.remove(styles['button-animation'])
      document.getElementById('display-mobile-navbar')?.classList.add(styles['reverse-button-animation'])
    } else {
      document.getElementById('display-mobile-navbar')?.classList.remove(styles['reverse-button-animation'])
      document.getElementById('display-mobile-navbar')?.classList.add(styles['button-animation'])
    }
    setExpandMenu(!expandMenu)
  }

  return (
    <>
      <button onClick={() => handleDisplayMenu()} className='absolute right-4 top-8 z-40 py-4 px-4' name='display menu'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="6" viewBox="0 0 24 6" fill="none" id='display-mobile-navbar'>
          <path d="M0.5 0.5H23.5M8.40625 5.5H23.5" stroke="white" strokeLinecap="round"/>
        </svg>
      </button>
      <div className={`fixed ${expandMenu ? styles['display-nav'] : styles.nav}`}>
        <Image width={20} height={31} src='/logo.svg' alt='logo' className='m-6' />
        <div className='flex flex-col items-start w-full h-fit pl-[20%] mt-[116px]'>
          {
            menuItems.map((menuItem, index) => {
              return (
                <div key={index}>
                  <div onClick={() => handleChooseItem(menuItem)}>
                    <a href={`#${menuItem}`} className='flex items-center mb-2 w-full'>
                      <div className={activeMenuItem === menuItem ? styles['active-box'] : styles.box}></div>
                      <h1 className={`xs:font-semibold md:font-bold font-[ThunderBold] ${activeMenuItem === menuItem ? styles['active-menu-item'] : styles['menu-item']}`}>{menuItem}</h1>
                    </a>
                  </div>
                  <div
                    className={`
                      my-2 w-[1px] bg-[#D9D9D9] ml-1 h-[108px]
                      ${styles['fill-animation']} 
                      ${activeMenuItem === menuItem && activeMenuItem !== 'newsletter' ? 'block relative' : 'hidden absolute'}`
                    }></div>
                </div>
              )
            })
          }
        </div>
        <div className={styles.shade}></div>
      </div>
    </>
  )
}
