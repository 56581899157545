import Image from 'next/image'
import { DragPreviewImage, useDrag } from 'react-dnd'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'

export default function Bone2Highlight ({ isSmallScreen, useLine, openModalMobile }: {isSmallScreen?: boolean, useLine?: boolean, openModalMobile?: any}) {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'bone2Highlight',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))
  const [isSafari, setIsSafari] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMove = (event: any) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    setPosition({ x: clientX - 140, y: -10 });
  };

  useEffect(() => {
    setTimeout(() => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      setIsSafari(isSafari)
    }, 100)
    const container: any = document.getElementById('image-preview-container2');
    if(container) {
      container.addEventListener('touchmove', handleMove);
    }

    return () => {
      if(container) {
        container.removeEventListener('touchmove', handleMove);
      }
    };
  }, []);

  useEffect(() => {
    if(position.x <= window.innerWidth - 175 && position.x > window.innerWidth - 183 && position.y > -25 && position.y < 25)
      openModalMobile(1)
  }, [position.x])

  useEffect(() => {
    if(isDragging) {
      document.getElementById('bone-2-highlight')?.classList.remove(styles.float)
    } else document.getElementById('bone-2-highlight')?.classList.add(styles.float)
  }, [isDragging])

  return (
    <div className="flex justify-center">
      {
        !isSmallScreen && <DragPreviewImage connect={preview} src={isSafari ? '/bone2-preview-safari.webp' : '/bone2-preview.webp'} />
      }
      {
        isDragging && useLine &&
        <div className={`absolute -top-[110%] w-[558px] ${isSafari ? '-left-[48%]' : '-left-[72%]'}`}>
          <svg width="266" height="373" viewBox="0 0 266 373" fill="none" xmlns="http://www.w3.org/2000/svg" className={isSafari ? styles['animation-safari'] : styles.animation}>
            <path d="M209.952 1H241.5C254.755 1 265.5 11.7452 265.5 25V159.5C265.5 172.755 254.755 183.5 241.5 183.5H24.5C11.2452 183.5 0.5 194.245 0.5 207.5V348.5C0.5 361.755 11.2452 372.5 24.5 372.5H85" stroke="#B0B0B0"/>
          </svg>
        </div>
      }
      <div className='h-[213px]'>
        <div id="image-preview-container2">
        {
          isSmallScreen && 
          <>
            <Image 
              src='/bone2-preview.webp' 
              width={100} 
              height={100} 
              alt='preview' 
              style={{
                transform: `translate(${position.x}px, ${position.y}px)`,
                position: 'absolute',
                display: isDragging  ? 'block' : 'none'
              }}
            />
            <Image
              ref={drag}
              src='/bone2-highlighted-with-shadow.webp'
              alt="bone"
              title="bone"
              width={64}
              height={178}
              className='w-auto h-[178px] mr-4'
            />
          </>
        }
        </div>
        {
          !isDragging && !isSmallScreen &&
          <Image
            ref={drag}
            src='/bone2-highlighted-with-shadow.webp'
            id='bone-2-highlight'
            alt="bone"
            title="bone"
            width={76}
            height={213}
            className={`${styles.float} w-auto h-[213px] mr-4`}
          />
        }
      </div>
    </div>
  )
}
