import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import DocsAnimation from '@components/molecules/DocsAnimation'
import WhitePaper from '@components/molecules/WhitePaper'
import styles from './styles.module.css'
import useTranslation from 'next-translate/useTranslation'
import { useIsMobile } from '@hooks/useIsMobile'

export default function Docs ({ setDiscoverMenuItem } : any) {
  const { ref, inView } = useInView({threshold: 0.5})
  const [shouldDisplayWhitepaper, setShouldDisplayWhitepaper] = useState(false)
  const [firstAccess, setFirstAccess] = useState(true);
  const { t, lang } = useTranslation('common')
  const isMobile = useIsMobile()

  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem('docs')
    }
  }, [inView])

  useEffect(() => {
    setFirstAccess(false)
  }, [setShouldDisplayWhitepaper])

  return (
    <section className='section' id='docs' ref={ref}>
      <div className='w-full py-[60px] md:py-[80px] px-4'>
        {
          !isMobile &&
          <DocsAnimation setShouldDisplayWhitepaper={setShouldDisplayWhitepaper}/>
        }
        {
          (shouldDisplayWhitepaper || isMobile) &&
          <div className={`mb-20 ml-0 md:ml-[25%] ${styles['opacity-animation']}`}>
            <h6 className={`${inView && 'section-title'}`}>/ {t('docs.title')}</h6>
            <WhitePaper firstAccess={firstAccess} />
          </div>
        }
      </div>
    </section>
  )
}
