import Image from 'next/image'
import { useDrop } from 'react-dnd'
export default function Bone3 ({ setOpenModal, isMobile }: any) {
  const moveBone3Highlight = () => {
    setOpenModal(2)
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'bone3Highlight',
    drop: () => moveBone3Highlight(),
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  }), [])

  return (
    <div className='flex justify-center'>
      <Image 
        ref={drop} 
        src='/bone3.svg' 
        alt='bone' 
        title='bone' 
        width={isMobile? 100 : 125} 
        height={isMobile ? 204 : 252} 
        style={{ height: !isMobile ? '245px': 'auto', marginTop: !isMobile ? '10px': '0px'}} 
      />
    </div>
  )
}
