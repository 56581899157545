import styles from "./styles.module.css";
import useRequest from "@hooks/useRequest";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export default function Newsletter({ setDiscoverMenuItem }: any) {
  const { ref, inView } = useInView({ threshold: 0.1 });

  const { t } = useTranslation("common");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [hasSubscribed, setHasSubscribed] = useState(false);

  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem(t("menu.newsletter"));
    }
  }, [inView]);

  const { request: sendEmail } = useRequest({
    url: `/newsletter/signup?email=${email}`,
    method: "post",
    onSuccess: () => {
      setHasSubscribed(true);
    },
    onError: (error: any) => {
      setHasSubscribed(false);
      if (error.response.status === 409) {
        setError(t("newsletter.alreadySubscribed"));
      }

      if (error.response.status === 422) {
        setError(t("newsletter.notValid"));
      }

      if (error.response.status === 500) {
        setError(t("newsletter.wrong"));
      }
    },
  });

  const handleSubscribe = () => {
    const testEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isValid = testEmailFormat.test(email);
    setHasSubscribed(false);

    if (!isValid) {
      setError(t("newsletter.notValid"));
      return;
    } else {
      setError(null);
      sendEmail();
    }
  };

  return (
    <section
      ref={ref}
      id={t("menu.newsletter")}
      className="pt-[80px] md:pt-[120px] mb-[120px] md:mb-[160px] flex flex-col items-center"
    >
      <h2 className={`xs:font-semibold md:font-bold mb-8 font-[ThunderBold]`}>
        {t("newsletter.stay")}
      </h2>
      <p className="max-w-[324px] text-center mb-[72px] text-white">
        {t("newsletter.subscribe")}
      </p>
      <div className="flex items-center md:items-start flex-col md:flex-row">
        <div className="relative">
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type={"email"}
            className={`${
              error !== null &&
              "text-white border !border-b-[#FE5151] placeholder-[#E2E2E2]"
            } px-4 text-sm block w-[250px] py-2 bg-[#111] text-[14px] border border-transparent border-b-primary text-white
             focus:border-b-primary focus:shadow-none focus:outline-none focus:border-transparent `}
            placeholder={"Email"}
          />

          {error !== null && (
            <p className="text-[12px] text-[#FE5151] absolute -bottom-[24px]">
              {error}
            </p>
          )}
          {hasSubscribed && (
            <p className="text-[10px] text-primary absolute -bottom-[24px]">
              {t("newsletter.hasSubscribed")}
            </p>
          )}
        </div>
        <button
          onClick={() => handleSubscribe()}
          className={`
            border-solid border-[1px] rounded-tl-[8px] rounded-br-[8px] font-light mt-8 md:mt-0
            border-primary text-primary flex items-center ${styles["hover-effect"]} w-fit md:ml-4
          `}
        >
          <span className="py-2 px-8 border-[1px] border-transparent rounded-tl-[8px] rounded-br-[8px] h-full w-full">
            {t("newsletter.submit")}
          </span>
        </button>
      </div>
    </section>
  );
}
