import ArrowDown from '@components/atoms/ArrowDown'
import { useIsTablet } from '@hooks/useIsTablet'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import useTranslation from 'next-translate/useTranslation'

export default function Header ({ setIntroMenuItem } : any) {
  const isTablet = useIsTablet()
  const { ref, inView } = useInView({threshold: 0.2})
  const { t, lang } = useTranslation('common')

  useEffect(() => {
    if (inView)
      setIntroMenuItem('intro')
  }, [inView])
  
  return (
    <section>
      <div className='w-full relative z-10' id='intro' ref={ref}>
        <div className='flex justify-center relative'>
          {
            isTablet 
              ?   <video width="auto" height="80vh"  autoPlay loop muted>
              <source src="/hero.mp4" type="video/mp4"/>
            </video>
            :   <video width="100%" height="auto"  autoPlay loop muted>
            <source src="/hero.mp4" type="video/mp4"/>
          </video>
          }
        
          <div className='absolute top-0 bottom-0 m-auto flex items-center'>
            <div className={isTablet ? 'mt-[170%]' : 'mt-64'}>
              <Image src='/bidhouse.svg' alt='Bidhouse' height={82} width={366} />
              <p className='text-white text-[16px] font-[BeVietnam] leading-[28px] text-center mt-6'>{t('header.description')} <br /> {t('header.description1')}.</p>
            </div>
          </div>
          <div className='absolute top-[93%]'>
            <ArrowDown />
          </div>
        </div>
      </div>
    </section>
  )
}
