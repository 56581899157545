import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import Bone1 from '@components/atoms/Bone1'
import Bone2Highlight from '@components/atoms/Bone2Hightlight'
import Bone3 from '@components/atoms/Bone3'
import Bone3Highlight from '@components/atoms/Bone3Highlight'
import Bone2 from '@components/atoms/Bone2'
import Bone1Highlight from '@components/atoms/Bone1Highlight'
import Image from 'next/image'
import Modal from '../Modal'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useInView } from 'react-intersection-observer'

export default function DiscoverCards () {
  const [openModal, setOpenModal] = useState(-1)
  const { t, lang } = useTranslation('common')
  const { ref, inView } = useInView({threshold: 0.2})

  const modalContent = [
    {
      title: t('discoverUs.aboutModal.title'),
      description: t('discoverUs.aboutModal.description'),
      content: [
        t('discoverUs.aboutModal.content1'),
        t('discoverUs.aboutModal.content2'),
        t('discoverUs.aboutModal.content3'),
        t('discoverUs.aboutModal.content4')
      ]
    },
    {
      title: t('discoverUs.missionModal.title'),
      description: t('discoverUs.missionModal.description'),
      content: [
        t('discoverUs.missionModal.content1'),
        t('discoverUs.missionModal.content2'),
        t('discoverUs.missionModal.content3'),
      ]
    },
    {
      title: t('discoverUs.storyModal.title'),
      description: t('discoverUs.storyModal.description'),
      content: [
        t('discoverUs.storyModal.content1'),
        t('discoverUs.storyModal.content2'),
        t('discoverUs.storyModal.content3'),
        t('discoverUs.storyModal.content4')
      ]
    },
  ]

  const handleOpenModal = (modal: number) => {
    setOpenModal(modal)
  }

  return (
    <>
      {
        openModal >= 0 &&
        <Modal open={openModal} setOpenModal={setOpenModal} title={modalContent[openModal].title} description={modalContent[openModal].description} content={modalContent[openModal].content} />
      }
      <div className='max-w-full md:max-w-[800px] w-full' style={{ transform: 'translateY(150px)' }}>
        <DndProvider backend={HTML5Backend}>
          <div className='grid grid-cols-3 w-full gap-[76px] gap-x-[218px] z-20'>
            <div 
              ref={ref}
              className={`relative w-full flex flex-col items-center ${!inView && 'opacity-0'} ${inView && 'opacity-animation'}`}
              style={{
                transform: inView ? 'none' : 'translateY(-100px)',
                transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
              }}
            >
              <Image
                src='/white-card.svg'
                alt='card'
                title='card'
                width={125}
                height={190}
              />
              <div className='absolute -top-16 left-0 right-0 m-auto'>
                <Bone1Highlight useLine={true} />
              </div>
              <h3 className='xs:font-semibold md:font-bold font-[ThunderBold] text-[#E2E2E2] mt-4'>{t('discoverUs.about')}</h3>
            </div>

            <div 
              className={`${inView && 'opacity-animation'} ${!inView && 'opacity-0'}`}
              style={{
                transform: inView ? 'translateY(-150px)' : 'translateY(-250px)',
                transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s',
              }}
            >
              <Bone2 setOpenModal={() => handleOpenModal(1)} />
            </div>

            <div 
              className={`relative w-full flex flex-col items-center ${!inView && 'opacity-0'} ${inView && 'opacity-animation-long'}`}
              style={{
                transform: inView ? 'none' : '',
                transition: 'all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
              }}
            >
              <Image
                src='/white-card.svg'
                alt='card'
                title='card'
                width={125}
                height={190}
              />
              <div className='absolute -top-12 left-0 right-0 m-auto'>
                <Bone3Highlight useLine={true} />
              </div>
              <h3 className='xs:font-semibold md:font-bold font-[ThunderBold] text-[#E2E2E2] mt-4'>{t('discoverUs.story')}</h3>
            </div>

            <div 
              className={`${inView && 'opacity-animation-long-with-delay'} ${!inView && 'opacity-0'}`}
              style={{
                transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
              }}
            >
              <Bone3 setOpenModal={() => handleOpenModal(2)} />
            </div>

            <div 
              className={`relative w-full flex flex-col items-center ${inView && 'opacity-animation-long-with-delay-middle'} ${!inView && 'opacity-0'}`} 
              style={{ transform: 'translateY(-50px)', transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s' }}
            >
              <Image
                src='/white-card.svg'
                alt='card'
                title='card'
                width={125}
                height={190}
              />
              <div className='absolute -top-12 left-0 right-0 m-auto'>
                <Bone2Highlight useLine={true} />
              </div>
              <h3 className='xs:font-semibold md:font-bold font-[ThunderBold] text-[#E2E2E2] mt-4'>{t('discoverUs.mission')}</h3>
            </div>

            <div
              className={`${inView && 'opacity-animation-long-with-delay'} ${!inView && 'opacity-0'}`}
              style={{
                transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
                animationDuration: '2s'
              }}
            >
              <Bone1 setOpenModal={() => handleOpenModal(0)} />
            </div>

          </div>
        </DndProvider>
      </div>
    </>
  )
}
