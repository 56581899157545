import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useIsTablet } from '@hooks/useIsTablet'

export default function BenefitCard ({index, title, content, list, conclusion, open, setActiveBenefit}: {index: number, title: string, content: string, list?: string[], conclusion?: string, open: boolean, setActiveBenefit: any}) {
  const [time, setTime] = useState(0)
  const isTablet = useIsTablet()

  useEffect(() => {
    const interval = setInterval(() => {
      const temp = time;
      if(time < 20) {
        setTime(temp + 0.2)
      } else {
        if(open) {
          index === 2 ? setActiveBenefit(0) : setActiveBenefit(index + 1)
          setTime(0)
        }
      }
    }, 200);
    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    if(open) {
      setTime(0)
    }
  }, [open])

  const handleOpen = () => {
    setActiveBenefit(index)
    setTime(0)
  }

  return (
    <div className='flip-card'>
      <div 
        className={`flip-card-inner ${open ? 'increase-dimensions' : 'lower-dimension'}`} 
        style={{ transform: open ? 'rotateY(0deg)': 'rotateY(180deg)'}}
      >
        <div className='flip-card-front'>
          <div 
            onClick={() => handleOpen()}
            key={index}
            id={`card${index}`}
            className={`
              h-[600px] flex flex-col rounded-tl-[24px] 
              rounded-br-[24px] bg-white py-12 px-6 md:px-10 w-[319px] md:w-[390px] 
              border-white hover:border-primary border-[1px] border-solid
              ${styles['hover-card']} ${styles['open-animation']}
            `}
          >
            <div>
              {
                isTablet && <h2 className='xs:font-semibold md:font-bold font-[ThunderBold] max-w-50'>{title}</h2>
              }
              {
                !isTablet && <h1 className='xs:font-semibold md:font-bold font-[ThunderBold] max-w-50'>{title}</h1>
              }
              <div className='max-h-[300px] overflow-y-scroll overflow-x-hidden mt-1 pr-2'>
                <p className='mt-4 w-full font-[BeVietnam]'>{content}</p>
                {
                  list && list.length > 0 && <>
                    <ul style={{ listStyleType: 'circle'}} className='pl-4'>
                      {
                        list.map((item: string) => {
                          return (
                            <li key={item} className='text-black'>{item}</li>
                          )
                        })
                      }
                    </ul>
                    <br />
                  </> 
                }
                {
                  !!conclusion && <div className='text-black'>{conclusion}</div>
                }
              </div>
            </div>
            <div>
              <div className='w-full bg-[#E2E2E2] h-[1px]'></div>
              <div 
                className='bg-[#111111] h-[1px] -mt-[1px] rounded-tr-full rounded-br-full'
                style={{ width: open ? `${time * 5}%` : '0px'}}
              ></div>
            </div>
          </div>
        </div>
        <div className='flip-card-back'>
        <div 
            onClick={() => handleOpen()}
            key={index}
            id={`card${index}`}
            className={`
              h-[600px] flex flex-col rounded-tl-[24px] 
              rounded-br-[24px] bg-white py-12 px-6 md:px-10 w-[319px] md:w-[390px] 
              border-white hover:border-primary border-[1px] border-solid
              ${styles['hover-card']} ${styles['close-animation']}
            `}
          >
            <div>
              {
                isTablet && <h2 className='xs:font-semibold md:font-bold font-[ThunderBold] max-w-50'>{title}</h2>
              }
              {
                !isTablet && <h1 className='xs:font-semibold md:font-bold font-[ThunderBold] max-w-50 text-[50px] md:text-[80px]'>{title}</h1>
              }
              <p className='mt-4 w-full font-[BeVietnam]'>{content}</p>
            </div>
              <button className='ml-[27px] md:ml-[50px]' name='open modal'>
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M41 21L21 21M21 21L1 21M21 21L21 41M21 21L21 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
          </div>
        </div>
      </div>
    </div>
  
  )
}
