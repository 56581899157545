import styles from "./styles.module.css";
import { useEffect, useState } from "react";

export default function BenefitCardMobile({
  index,
  title,
  content,
  list,
  conclusion,
  open,
  setActiveBenefit,
}: {
  index: number;
  title: string;
  content: string;
  list?: string[];
  conclusion?: string;
  open: boolean;
  setActiveBenefit: any;
}) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (open) {
      document
        .getElementById(`card${index}`)
        ?.classList.remove(styles["close-animation"]);
      document
        .getElementById(`card${index}`)
        ?.classList.add(styles["open-animation"]);
      setTime(0);
    } else {
      document
        .getElementById(`card${index}`)
        ?.classList.remove(styles["open-animation"]);
      document
        .getElementById(`card${index}`)
        ?.classList.add(styles["close-animation"]);
    }
  }, [open]);

  useEffect(() => {
    const interval = setInterval(() => {
      const temp = time;
      if (time < 20) {
        setTime(temp + 0.2);
      } else {
        if (open) {
          index === 2 ? setActiveBenefit(0) : setActiveBenefit(index + 1);
          setTime(0);
        }
      }
    }, 200);
    return () => clearInterval(interval);
  }, [time]);

  const handleOpen = () => {
    setActiveBenefit(index);
    setTime(0);
  };

  return (
    <div
      onClick={() => handleOpen()}
      key={index}
      id={`card${index}`}
      className={`
        h-[500px] flex rounded-tl-[24px] justify-start
        rounded-br-[24px] bg-white py-8 px-6 w-full mb-6
        border-white hover:border-primary border-[1px] border-solid
        ${styles["hover-card"]} ${open ? "flex-col" : "flex-row items-center justify-between"}
      `}
    >
      <div>
        <h1 className="xs:font-semibold md:font-bold font-[ThunderBold] max-w-50 text-[50px]">
          {title}
        </h1>
      </div>
      <>
        {open && (
          <div
            className={`w-full flex flex-col justify-between h-full ${styles["progressbar-animation"]}`}
          >
            <div className="max-h-[300px] overflow-scroll my-1 pr-1">
              <p className="mt-4 w-full font-[BeVietnam]">{content}</p>
              {list && list.length > 0 && (
                <>
                  <ul style={{ listStyleType: "circle" }} className="pl-4">
                    {list.map((item: string) => {
                      console.log(item);
                      return (
                        <li key={item} className="text-black">
                          {item}
                        </li>
                      );
                    })}
                  </ul>
                  <br />
                </>
              )}
              {!!conclusion && <div className="text-black">{conclusion}</div>}
            </div>
            <div>
              <div className="w-full bg-[#E2E2E2] h-[1px]"></div>
              <div
                className="bg-[#111111] h-[1px] -mt-[1px] rounded-tr-full rounded-br-full"
                style={{ width: `${time * 5}%` }}
              ></div>
            </div>
          </div>
        )}
      </>
      {!open && (
        <button name="open modal">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.9053 7H7.40527M7.40527 7L0.905273 7M7.40527 7V13.5M7.40527 7L7.40527 0.5"
              stroke="white"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
