import { useState } from 'react'
import styles from './styles.module.css'
import Image from 'next/image'

export default function SocialMenu () {
  const [displaySocial, setDisplaySocial] = useState(false)

  const social = [
    {
      logo: 'facebook',
      link: 'https://www.facebook.com/bidhouseclub'
    },
    {
      logo: 'instagram',
      link: 'https://www.instagram.com/bidhouseclub/'
    },
    {
      logo: 'discord',
      link: 'https://discord.com/invite/bidhouseclub'
    },
    {
      logo: 'twitter',
      link: 'https://twitter.com/bidhouseclub'
    },
    {
      logo: 'telegram',
      link: 'https://t.me/BidHouseClub'
    }
  ]

  const handleDisplaySocial = () => {
    setDisplaySocial(!displaySocial)
    if (!displaySocial) {
      document.getElementById('header-social-svg')?.classList.add('rotate-45')
      document.getElementById('header-social-svg')?.classList.add('duration-200')
      document.getElementById('header-social-svg')?.classList.add(styles['adjust-color'])
      document.getElementById('header-social-button')?.classList.remove('px-[10px]')
      document.getElementById('header-social-button')?.classList.add('px-[5px]')
    } else {
      document.getElementById('header-social-svg')?.classList.remove('rotate-45')
      document.getElementById('header-social-button')?.classList.remove('px-[5px]')
      document.getElementById('header-social-svg')?.classList.remove(styles['adjust-color'])
      document.getElementById('header-social-button')?.classList.add('px-[10px]')
    }
  }

  const handleAddEffect = () => {
    if (displaySocial) {
      document.getElementById('header-social-button')?.classList.add('border-white')
    } else {
      document.getElementById('header-social-button')?.classList.add('border-primary')
    }
  }

  const handleRemoveEffect = () => {
    document.getElementById('header-social-button')?.classList.remove('border-primary')
    document.getElementById('header-social-button')?.classList.add('border-white')
  }

  return (
    <>
      <button
        onClick={() => handleDisplaySocial()}
        onMouseEnter={() => handleAddEffect()}
        onMouseLeave={() => handleRemoveEffect()}
        id='header-social-button'
        name='display social media icons'
        className={`
          py-[10px] px-[10px]
          border-white border-[1px] border-solid rounded-[4px]
          w-fit h-fit ${styles['fill-yellow']}
          flex flex-col items-center bg-[#111]
        `}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none" id='header-social-svg'>
          <path d="M13 6.5H6.5M6.5 6.5L0 6.5M6.5 6.5V13M6.5 6.5L6.5 0" stroke="white"/>
        </svg>
        {
          displaySocial &&
          <div className='mt-4'>
            {
              social.map((socialItem, index) => {
                return (
                  <div key={index} className='mt-6'>
                    <a href={socialItem.link} target='_blank' title={`social - ${socialItem.logo}`} className='relative'>
                      <Image
                        src={`/social/${socialItem.logo}.svg`}
                        alt={`social - ${socialItem.logo}`}
                        width={24}
                        height={24}
                        className='filter'
                      />
                    </a>
                  </div>
                )
              })
            }
          </div>
        }
      </button>
    </>
  )
}
