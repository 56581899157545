import { useIsMobile } from '@hooks/useIsMobile'
import Image from 'next/image'
import { useDrop } from 'react-dnd'

export default function Bone1 ({ setOpenModal, isMobile }: any) {
  const moveBone1Highlight = () => {
    setOpenModal(0)
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'bone1Highlight',
    drop: () => moveBone1Highlight(),
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  }), [])

  return (
    <div ref={isMobile ? drop : null} className='flex justify-center' onClick={() => moveBone1Highlight()}>
      <Image ref={isMobile ? null : drop} src='/bone1.svg' alt='bone' title='bone' width={isMobile? 100 : 125} height={isMobile ? 204 : 252} />
    </div>
  )
}
