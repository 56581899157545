import { useEffect, useState } from 'react'
import BenefitCard from '@components/atoms/BenefitCard'
import { useIsMobile } from '@hooks/useIsMobile'
import BenefitCardMobile from '@components/atoms/BenefitCardMobile'
import { useInView } from 'react-intersection-observer'
import useTranslation from 'next-translate/useTranslation'

export default function Benefits () {
  const [activeBenefit, setActiveBenefit] = useState(-1);
  const [firstTime, setFirstTime] = useState(false);
  const isMobile = useIsMobile();
  const { ref, inView } = useInView({threshold: 0.2});
  const { t, lang } = useTranslation('common')

  const benefits= [
    {
      title: t('benefits.benefit.title'),
      content: t('benefits.benefit.content')
    },
    {
      title: t('benefits.benefit1.title'),
      content: t('benefits.benefit1.content')
    },
    {
      title: t('benefits.benefit2.title'),
      content: t('benefits.benefit2.content'),
      list: [
        t('benefits.benefit2.benefit1'),
        t('benefits.benefit2.benefit2'),
        t('benefits.benefit2.benefit3')
      ],
      conclusion: t('benefits.benefit2.conclusion')
    }
  ]

  useEffect(() => {
    if (inView && !firstTime) {
      setActiveBenefit(0)
      setFirstTime(true)
    } 
  }, [inView])

  return (
    <div ref={ref} className={`flex w-full justify-center benefit-cards-margin ${isMobile && 'h-[800px]'}`}>
     {
      benefits.map(((benefit: {title: string, content: string, list?: string[], conclusion?: string}, index: number) => {
        return (
          <div key={index} className={`h-full ${isMobile ? 'w-full' : 'w-fit'} mr-4 md:mr-6 last:mr-0`}>
            {
              isMobile 
                ? <BenefitCardMobile 
                index={index} 
                title={benefit.title} 
                content={benefit.content} 
                list={benefit?.list}
                conclusion={benefit?.conclusion}
                open={index === activeBenefit} 
                setActiveBenefit={setActiveBenefit}
              />
                : <BenefitCard 
                index={index} 
                title={benefit.title} 
                content={benefit.content} 
                list={benefit?.list}
                conclusion={benefit?.conclusion}
                open={index === activeBenefit} 
                setActiveBenefit={setActiveBenefit}
            />
            }
          </div>
        )
      }))
     }
    </div>
  )
}
