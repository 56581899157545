import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import Benefits from '@components/molecules/Benefits'
import useTranslation from 'next-translate/useTranslation'

export default function OurBenefits ({ setDiscoverMenuItem } : any) {
  const { ref, inView } = useInView()
  const { t, lang } = useTranslation('common')

  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem(t('menu.benefits'))
    }
  }, [inView])

  return (
    <section 
      ref={ref}
      id={t('menu.benefits')}
      className={`section mb-[60px] md:mb-[80px] ${!inView && 'opacity-0'}`}
    >
      <div className='w-full px-4'>
        <div className={`${inView && 'section-title'} mb-20 ml-[25%]`}>
          <h6>/ {t('benefits.title')}</h6>
        </div>
        <div 
          className={`flex justify-center ${!inView && 'opacity-0'} ${inView && 'opacity-animation-long'}`}
          style={{
            transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
            animationDuration: '1s'
          }}
        >
          <Benefits />
        </div>
      </div>
    </section>
  )
}
