import { TouchBackend } from 'react-dnd-touch-backend'
import { DndProvider } from 'react-dnd'
import Bone1 from '@components/atoms/Bone1'
import Bone2Highlight from '@components/atoms/Bone2Hightlight'
import Bone3 from '@components/atoms/Bone3'
import Bone3Highlight from '@components/atoms/Bone3Highlight'
import Bone2 from '@components/atoms/Bone2'
import Bone1Highlight from '@components/atoms/Bone1Highlight'
import Image from 'next/image'
import Modal from '../Modal'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useInView } from 'react-intersection-observer'
export default function DiscoverCardsMobile () {
  const [openModal, setOpenModal] = useState(-1);
  const { t, lang } = useTranslation('common');
  const { ref, inView } = useInView({threshold: 0.2})

  const modal = [
    {
      title: t('discoverUs.aboutModal.title'),
      description: t('discoverUs.aboutModal.description'),
      content: [
        t('discoverUs.aboutModal.content1'),
        t('discoverUs.aboutModal.content2'),
        t('discoverUs.aboutModal.content3'),
        t('discoverUs.aboutModal.content4')
      ]
    },
    {
      title: t('discoverUs.missionModal.title'),
      description: t('discoverUs.missionModal.description'),
      content: [
        t('discoverUs.missionModal.content1'),
        t('discoverUs.missionModal.content2'),
        t('discoverUs.missionModal.content3'),
      ]
    },
    {
      title: t('discoverUs.storyModal.title'),
      description: t('discoverUs.storyModal.description'),
      content: [
        t('discoverUs.storyModal.content1'),
        t('discoverUs.storyModal.content2'),
        t('discoverUs.storyModal.content3'),
        t('discoverUs.storyModal.content4')
      ]
    },
  ]
  const handleOpenModal = (modal: number) => {
    setOpenModal(modal)
  }

  return (
    <>
      {
        openModal >= 0 &&
        <Modal open={openModal} setOpenModal={setOpenModal} title={modal[openModal].title} description={modal[openModal].description} content={modal[openModal].content} />
      }
      <div 
      ref={ref}
        className={`w-full ${!inView && 'opacity-0'} ${inView && 'opacity-animation'}`}
        style={{
          transform: inView ? 'none' : 'translateY(-100px)',
          transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
        }}
      >
        <DndProvider backend={TouchBackend}>
          <div className='grid grid-cols-2 w-full gap-[60px] gap-x-[110px] z-20'>
            <div className='relative w-full flex flex-col items-center justify-end'>
              <Image
                src='/white-card.svg'
                alt='card'
                title='card'
                width={100}
                height={155}
              />
              <div className='absolute top-0 sm:-top-16 left-0 right-0 m-auto'>
                <Bone1Highlight isSmallScreen={true} openModalMobile={handleOpenModal} />
              </div>
              <h3 className='xs:font-semibold md:font-bold text-[#E2E2E2] mt-4 font-[ThunderBold]'>{t('discoverUs.about')}</h3>
            </div>

            <div>
              <Bone1 setOpenModal={() => handleOpenModal(0)} isMobile={true} />
              <div className='h-[71px]'></div>
            </div>

            <div>
              <Bone3 setOpenModal={() => handleOpenModal(2)} isMobile={true} />
              <div className='h-[71px]'></div>
            </div>

            <div className='relative w-full flex flex-col items-center justify-end'>
              <Image
                src='/white-card.svg'
                alt='card'
                title='card'
                width={100}
                height={155}
              />
              <div className='absolute -top-[3%] sm:-top-12 left-0 right-0 m-auto'>
                <Bone3Highlight isSmallScreen={true} openModalMobile={handleOpenModal} />
              </div>
              <h3 className='xs:font-semibold md:font-bold text-[#E2E2E2] mt-4 font-[ThunderBold]'>{t('discoverUs.story')}</h3>
            </div>

            <div className='relative w-full flex flex-col items-center justify-end'>
              <Image
                src='/white-card.svg'
                alt='card'
                title='card'
                width={100}
                height={155}
              />
              <div className='absolute top-0 sm:-top-12 left-0 right-0 m-auto'>
                <Bone2Highlight isSmallScreen={true} openModalMobile={handleOpenModal}/>
              </div>
              <h3 className='xs:font-semibold md:font-bold text-[#E2E2E2] mt-4 font-[ThunderBold]'>{t('discoverUs.mission')}</h3>
            </div>

            <div>
              <Bone2 setOpenModal={() => handleOpenModal(1)} isMobile={true} />
              <div className='h-[71px]'></div>
            </div>
          </div>
        </DndProvider>
      </div>
    </>
  )
}
