import ArrowDown from '@components/atoms/ArrowDown'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export default function HeaderMobile ({ setIntroMenuItem } : any) {
  const { ref, inView } = useInView({threshold: 0.2})
  const { t, lang } = useTranslation('common')

  useEffect(() => {
    if (inView) {
      setIntroMenuItem(true)
    } else setIntroMenuItem(false)
  }, [inView])
  return (
    <section>
      <div className='w-full relative' id='intro' ref={ref}>
        <div className='flex justify-center relative'>
          {/* <video width="auto" height="80vh"  autoPlay loop muted className='max-w-none h-[80vh]'>
            <source src="/hero.mp4" type="video/mp4"/>
          </video> */}
          <Image priority={true} fill={true} src='/header-image-mobile.webp' alt='Header image' className='!relative w-full' />
          <div className='absolute bottom-20 flex flex-col items-center'>
            <Image src='/bidhouse.svg' alt='Bidhouse' height={46} width={156} />
            <p className='text-white text-[16px] font-[BeVietnam] leading-[28px] text-center mt-6'>{t('header.description')} <br /> {t('header.description1')}.</p>
          </div>
          <div className='absolute bottom-0'>
            <ArrowDown />
          </div>
        </div>
      </div>
    </section>
  )
}
