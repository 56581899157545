import DiscoverCards from '@components/molecules/DiscoverCards'
import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useIsTablet } from '@hooks/useIsTablet'
import { useIsMobile } from '@hooks/useIsMobile'
import DiscoverCardsTablet from '@components/molecules/DiscoverCardsTablet'
import DiscoverCardsMobile from '@components/molecules/DiscoverCardsMobile'
import useTranslation from 'next-translate/useTranslation'

export default function Discover ({ setDiscoverMenuItem } : any) {
  const { ref, inView } = useInView({threshold: 0.2})
  const isTablet = useIsTablet()
  const isMobile = useIsMobile()
  const { t, lang } = useTranslation('common')

  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem(t('menu.discover'))
    } 
  }, [inView])

  return (
    <section 
      id={t('menu.discover')} 
      ref={ref}
      className={`w-full section ${!inView && 'opacity-0'}`}
    >
      <div className='w-full py-[120px] md:pt-[160px] md:pb-[310px] px-4'>
        <div className={`${inView && 'section-title'} mb-20 ml-[25%]`}>
          <h6>/ {t('discoverUs.title')}</h6>
          <p className='text-white font-[BeVietnam] text-[16px] font-normal leading-[28px] mt-10 max-w-[476px]'>
            {t('discoverUs.description')}
          </p>
        </div>
        <div 
          className='flex justify-center'
        >
          {
            !isTablet && !isMobile &&  <DiscoverCards />
          }
          {
            isTablet && !isMobile && <DiscoverCardsTablet />
          }
          {
            isMobile && <DiscoverCardsMobile />
          }
        </div>
      </div>
    </section>
  )
}
